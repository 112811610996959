import { registerLocaleData } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import en from '@angular/common/locales/en';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TransferHttpCacheModule } from '@nguniversal/common';
import { LocalStorage } from '@ngx-pwa/local-storage';
import { en_US, NZ_I18N } from 'ng-zorro-antd';
import { NzMessageModule } from 'ng-zorro-antd/message';
import { CookieService } from 'ngx-cookie-service';
import { IConfig, NgxMaskModule } from 'ngx-mask';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { IconsProviderModule } from './icons-provider.module';
import { CommonService } from './shared/common.service';
import { SharedModule } from './shared/shared.module';
import { HttpErrorInterceptor, JwtInterceptor } from './_helpers';

import { AuthServiceConfig, FacebookLoginProvider, GoogleLoginProvider, SocialLoginModule } from 'angularx-social-login';
// import { SocialLoginModule, AuthServiceConfig } from 'angularx-social-login';
// import { GoogleLoginProvider, FacebookLoginProvider } from 'angularx-social-login';
import { PdfJsViewerModule } from 'ng2-pdfjs-viewer';
// import { NzUploadModule } from 'ng-zorro-antd/upload';
// import { SupportComponent } from './support/support.component';
// import { TermsConditionsComponent } from './terms-conditions/terms-conditions.component';
// import { HomepageComponent } from './pages/homepage/homepage.component';
registerLocaleData(en);

let config = new AuthServiceConfig([
    {
        id: GoogleLoginProvider.PROVIDER_ID,
        // provider: new GoogleLoginProvider('390434293627-lfrdfbod2nli13s2ucp50bfgp9vn8uav.apps.googleusercontent.com')
        provider: new GoogleLoginProvider('390434293627-jij5090g5dlovrk25ko3lcgcckh8pmh8.apps.googleusercontent.com')
        // provider: new GoogleLoginProvider('629798901077-9a4mf15t8vce5c76pp9naqo83kpop9h7.apps.googleusercontent.com')
    },
    {
        id: FacebookLoginProvider.PROVIDER_ID,
        provider: new FacebookLoginProvider('1451243675049864')
    }
]);

export function provideConfig() {
    return config;
}
const options: Partial<IConfig> | (() => Partial<IConfig>) = {} = {};

@NgModule({
    declarations: [
        AppComponent,
        // SupportComponent,
        // TermsConditionsComponent,
        // HomepageComponent
    ],
    imports: [
        BrowserModule.withServerTransition({ appId: 'fx-connect' }),
        AppRoutingModule,
        NgxMaskModule.forRoot(options),
        SharedModule,
        IconsProviderModule,
        BrowserAnimationsModule,
        TransferHttpCacheModule,
        HttpClientModule,
        NzMessageModule,
        PdfJsViewerModule,
        SocialLoginModule,

    ],
    providers: [

        { provide: NZ_I18N, useValue: en_US },
        LocalStorage,
        CookieService,
        CommonService,
        { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true },
        {
            provide: AuthServiceConfig,
            useFactory: provideConfig
        }
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
