import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import moment from "moment";
import { Observable, Subject } from 'rxjs';
import { URLS } from "../_config/api.config";

@Injectable()
export class  CommonService {
    private notificationCount: string = URLS.notificationCount;

    private field: string;
    public eventObservable = new Subject<any>();
    public event: any = {};
  
    public customGroupEventObservable = new Subject<any>();
    public customGroupevent: any = {};

     public customEventObservable = new Subject<any>();
    public customevent: any = {};
    // customEventObservable$ = this.customEventObservable.asObservable();

    constructor(public http: HttpClient) { }

    setData(val) {
        this.field = val;
    }

    getData() {
        return this.field;
    }

    clearEvent() {
        this.eventObservable.next();
    }

    /**	    
     * @param key : Property name sets user data with a named key as passed key in argument
     * @param data : Setter sets user data on passed key in argument
     */

    public setEvent(event: string, data: any) {	    
        this.event = {'event': event, 'data': data};	
        this.eventObservable.next(this.event);	
    }

     public setEventCustomSub(event: string, data: any) {	    
        this.customevent = {'event': event, 'data': data};	
        this.customEventObservable.next(this.customevent);	
    }

    clearCustomEvent() {
        this.customEventObservable.next();
    }

    /**	    
     * @param key : Property name sets user data with a named key as passed key in argument
     * @param data : Setter sets user data on passed key in argument
     */

    public setCustomGroupEvent(event: string, data: any) {
        this.customGroupevent = {'event': event, 'data': data};	

        this.customGroupEventObservable.next(this.customGroupevent);	
    }

     /**
      * CONVERT DATE AS PER LOGGED IN USER'S TIMEZONE
      * @param dateStr CREATED DATE
    */
      public convertIntoLocalDate(dateStr): string {
        let date = moment.utc(dateStr);
        return moment(date).format();
    }
    
    public getCount(): Observable<{}> {
        return this.http.get(this.notificationCount);
    }
    
}