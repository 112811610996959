import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-social-share',
  templateUrl: './social-share.component.html',
  styleUrls: ['./social-share.component.scss']
})
export class SocialShareComponent implements OnInit {

  @Input() type: 'facebook' | 'twitter' | 'linkedin' | 'pinterest' | 'googleplus' | 'whatsapp';
  @Input() shareUrl: string;
  @Input() imageUrl: string;
  @Input() textvalue: string;

  navUrl: string;

  constructor() { }

  ngOnInit() {
    this.createNavigationUrl();
  }

  private createNavigationUrl() {
    let searchParams = new URLSearchParams();

    // TODO: zrobić map z tego manualnego dziugania

    switch(this.type) {
      case 'facebook':
        searchParams.set('u', this.shareUrl);
        this.navUrl = 'https://www.facebook.com/sharer/sharer.php?' + searchParams;
        break;
    //   case 'twitter':
    //     searchParams.set('url', this.shareUrl);
    //     this.navUrl =  'https://twitter.com/share?' + searchParams;
    //     break;
        

         case 'twitter':
        searchParams.set('url', this.shareUrl);
        this.navUrl =  'https://www.pinterest.com/pin/create/button/' + searchParams;
        break;
    }
  }

  public share() {
    return window.open(this.navUrl, "_blank");
  }

}