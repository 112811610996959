import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CommonService } from './../shared/common.service';
import { CookieService } from 'ngx-cookie-service';
import { Observable } from 'rxjs';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
    custom_Id : number = 0 ;
    constructor(
        private cookieService: CookieService,
        private activeRoute: ActivatedRoute,
        private commonService: CommonService
        ) {
        this.activeRoute.queryParams.subscribe(params => {
            const customerKey = Object.keys(params)[0];
            if (customerKey === 'token') {
                // const staticToken = 'd1613b6a-ff97-11e8-8eb2-f2801f1b9fd1';
                // this.cookieService.set('token', staticToken);
            }
        });

        this.commonService.customGroupEventObservable.subscribe((obj) => {
            this.custom_Id = obj['data']['data']
        })
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        let token;
        token = this.cookieService.get('token') ? this.cookieService.get('token') : localStorage.getItem('token');
        const headers = {};
        if (token) {
            headers['Authorization'] = token;
            let subId:any =  localStorage.getItem('subId');
            if(subId){
                headers['subscriptionid'] =  subId.toString();
            }
        } else {
            headers['authorization'] = 'd1613b6a-ff97-11e8-8eb2-f2801f1b9fd1';
        }
        headers['Cache-Control'] = 'no-cache',
            headers['Pragma'] = 'no-cache';
        // headers['language_code'] = 'en';
        // headers['devicetype'] = 'ios';
        //  headers['Content-Type'] = 'application/json';
        if (request.url.includes('https://api.dropboxapi.com/') != true) {
            request = request.clone({
                setHeaders: headers
            });
        }
        return next.handle(request);
    }
}
