import { Pipe, PipeTransform, Inject, LOCALE_ID } from '@angular/core';
// import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { DomSanitizer, SafeHtml, SafeStyle, SafeScript, SafeUrl, SafeResourceUrl } from '@angular/platform-browser';

// @Pipe({
// 	name: 'safe'
// })
// export class CommonPipe implements PipeTransform {

// 	constructor(protected sanitizer: DomSanitizer) { }

// 	public transform(value: any, type: string): SafeHtml | SafeStyle | SafeScript | SafeUrl | SafeResourceUrl {
// 		switch (type) {
// 			case 'html': return this.sanitizer.bypassSecurityTrustHtml(value);
// 			case 'style': return this.sanitizer.bypassSecurityTrustStyle(value);
// 			case 'script': return this.sanitizer.bypassSecurityTrustScript(value);
// 			case 'url': return this.sanitizer.bypassSecurityTrustUrl(value);
// 			case 'resourceUrl': return this.sanitizer.bypassSecurityTrustResourceUrl(value);
// 			default: throw new Error(`Invalid safe type specified: ${type}`);
// 		}
// 	}
// }

@Pipe({
  name: 'truncate'
})
export class TruncatePipe implements PipeTransform {
  // constructor(@Inject(LOCALE_ID) private locale: string) { }
  transform(value: any, limit = 50, completeWords = false, ellipsis = '...') {
    value = value.toString();
    if (value.length < limit) {
        return `${value.substr(0, limit)}`;
    }

    if (completeWords) {
      limit = value.substr(0, limit).lastIndexOf(' ');
    }
    return `${value.substr(0, limit)}${ellipsis}`;
  }
}
