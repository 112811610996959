import { environment } from './../../environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, from, Observable, ReplaySubject, Subject } from 'rxjs';
import { filter, flatMap, map, tap } from 'rxjs/operators';
import * as crypto from 'crypto-js';
import { URLS, SECRET_KEY } from '../_config/api.config';
import { CookieService } from 'ngx-cookie-service';
import { CometChat } from '@cometchat-pro/chat';


@Injectable({ providedIn: 'root' })
export class AuthenticationService {
    private currentUserSubject: BehaviorSubject<any>;
    public currentUser: Observable<any>;
    // secret key to encrypt the login credentails
    private secretKey: string = SECRET_KEY;

    // onserveable logged in status
    public loggedInStatus;

    // login api url
    private loginUrl = URLS.login;
    private initialized: Subject<boolean> = new ReplaySubject();
    private signedIn$: Subject<string> = new ReplaySubject();
    private _signedIn: boolean = false;
    // logout api url
    private logoutUrl = URLS.logout;
private deleteAccUrl = URLS.deleteAccount;
    constructor(private http: HttpClient, private cookieService: CookieService) {
    // this.cometInit();
        this.loggedInStatus = new BehaviorSubject<boolean>(this.hasToken());
        // this.currentUserSubject = new BehaviorSubject<any>(JSON.parse(localStorage.getItem('currentUser')));
        // this.currentUser = this.currentUserSubject.asObservable();
    }

    //   public get currentUserValue(): any {
    //     return this.currentUserSubject.value;
    // }


    /**
  * if we have token the user is loggedIn
  * @returns {boolean}
  */
    private hasToken(): boolean {
        return !!this.cookieService.get('token') ? !!this.cookieService.get('token') : !!localStorage.getItem('token') ;
    }



    /**
    *
    * @returns {Observable<T>}
    */
    public isLoggedIn() {
        return this.loggedInStatus.asObservable();
    }

    /**
*  Login the user then tell all the subscribers about the new status
*/

cometInit(){
    let appID = environment.cometChat.appId;
      let region = "us";
      let appSetting = new CometChat.AppSettingsBuilder()
                          .subscribePresenceForAllUsers()
                          .setRegion(region)
                          .autoEstablishSocketConnection(true)
                          .build();
      CometChat.init(appID, appSetting).then(
        () => {
          console.log("Initialization completed successfully");
        }, error => {
          console.log("Initialization failed with error:", error);
        }
      );
}
    public login(loginData: any, resendCipher?) {
        const resendData = resendCipher ? resendCipher : null;

        const cipherData = crypto.AES.encrypt(JSON.stringify(loginData), this.secretKey).toString();
        // const cipherPasswrd = crypto.AES.encrypt(password, this.secretKey).toString();
        return this.http.post<any>(`${this.loginUrl}`, { 'data': resendData !== null ? resendData : cipherData })
            .pipe(map(user => {
                // login successful if there's a jwt token in the response
                if (user && user.code === 200) {
                    localStorage.setItem('token', user.data['token']);
                    this.cookieService.set('token', user.data['token']);

                    this.cookieService.set('isPfComplete', user['data']['isProfileComplete']);
                    localStorage.setItem('isPfComplete', user['data']['isProfileComplete']);

                    this.loggedInStatus.next(true);
                    // store user details and jwt token in local storage to keep user logged in between page refreshes
                    //  localStorage.setItem('currentUser', JSON.stringify(user));
                    //  this.currentUserSubject.next(user);
                    return user;
                } else if (user && user.code === 405) {
                    return user;
                } else {
                    return false;
                }
            }));
    }

    public logout() {

        this.loggedInStatus.next(false);
        this.initialized.next(false);
        // localStorage.removeItem('token');
        // localStorage.clear()
         localStorage.removeItem('currentUser');
         localStorage.removeItem('currentSub');
         localStorage.removeItem('CustDrop');
         localStorage.removeItem('subId');
        return this.http.get<any>(`${this.logoutUrl}`);
        // remove user from local storage to log user out
        

        //  this.currentUserSubject.next(null);
    }


    public getSignedIn(): Observable<string> {
        return this.signedIn$;
    }
    public isSignedIn(): boolean {
        return this._signedIn;
    }

    public deleteAccount(dataParams : any): Observable<{}> {
      return this.http.post(`${this.deleteAccUrl}` , dataParams);
    }
}
