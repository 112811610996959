import { environment } from '../../environments/environment';

export const URLS = Object({
    'login': `${environment.apiUrl}/customer/login`,
    'logout': `${environment.apiUrl}/customer/logout`,
    'resetPassword': `${environment.apiUrl}/customer/resetpassword`,
    'forgetPassword': `${environment.apiUrl}/customer/forgetpassword`,
    'currentSubs': `${environment.apiUrl}/customer/current-subscription`,
    'updateSubs': `${environment.apiUrl}/customer/update-subscription`,
    'cancelSubs': `${environment.apiUrl}/customer/cancel-subscription`,
    'register': `${environment.apiUrl}/customer-web/register`,
    'socialregister': `${environment.apiUrl}/customer-web/socialregister`,
    'customerprofile': `${environment.apiUrl}/customer/details`,
    'customerUpdate': `${environment.apiUrl}/customer/update`,
    'billingInfo': `${environment.apiUrl}/customer/fetch-invoices`,
    'subscriptionRefund': `${environment.apiUrl}/customer-web/subscription/refundrequest`,
    'cardInfo': `${environment.apiUrl}/customer/get-card`,
    'registrationComplete': `${environment.apiUrl}/customer-web/registercomplete`,
    'verificationCode': `${environment.apiUrl}/customer-web/verify`,
    'discountVerify': `${environment.apiUrl}/discount/verify`,
    'discountApply': `${environment.apiUrl}/subscription/discount`,
    'addonsDiscountVerify': `${environment.apiUrl}/addonsdiscount/verify `,
    'resendOTP': `${environment.apiUrl}/customer-web/resendcode`,
    'dependencies': `${environment.apiUrl}/customer/alldependencies`,
    'stateList': `${environment.apiUrl}/customer/state`,
    'videoListing': `${environment.apiUrl}/video/list`,
    'audioListing': `${environment.apiUrl}/audio/list`,
    'ebookListing': `${environment.apiUrl}/ebook/list`,
    'articleListing': `${environment.apiUrl}/article/list`,
    'videoDetail': `${environment.apiUrl}/video/details`,
    'audioDetail': `${environment.apiUrl}/audio/details`,
    'ebookDetail': `${environment.apiUrl}/ebook/details`,
    'articleDetail': `${environment.apiUrl}/article/details`,
    'favoriteListing': `${environment.apiUrl}/customer-favourite/list`,
    'removeFavorite': `${environment.apiUrl}/customer-favourite/remove`,
    'addFavorite': `${environment.apiUrl}/customer-favourite/add`,
    'pdfDetail': `${environment.apiUrl}/pdf/download`,
    'renderPdf': `${environment.apiUrl}/pdf/render-pdf`,
    'addonsPDFDetail': `${environment.apiUrl}/addons/pdf/download`,
    'faq': `${environment.apiUrl}/faq/list`,
    'termsAndCondition': `${environment.apiUrl}/cms/getcmspage?cms_slug=termsandconditions`,
    'filterAPI': `${environment.apiUrl}/customer/alldependencies`,
    'addedFilterAPI': `${environment.apiUrl}/master/filterdata`,
    'updateCard': `${environment.apiUrl}/customer/update-card`,
    'emailverify': `${environment.apiUrl}/customer/emailverify`,
    'socialURL': `${environment.apiUrl}/customer/sociallogin`,
    'subscriptionList': `${environment.apiUrl}/subscription/list`,
    'addOnListing': `${environment.apiUrl}/customer-web/addonlist`,
    'addOnListingPublic': `${environment.apiUrl}/customer-web/addonlist/public`,
    'purchaseAddOn': `${environment.apiUrl}/customer-web/purchase/addons`,
    'purchasedAddOnListing': `${environment.apiUrl}/customer-web/purchasedaddons/list`,
    'purchasedAddOnDetail': `${environment.apiUrl}/customer-web/purchasedaddons/details`,
    'addOnDetail': `${environment.apiUrl}/customer-web/addondetails`,
    'addonRefund': `${environment.apiUrl}/customer-web/addons/refundrequest`,
    'purchaseInfo': `${environment.apiUrl}/customer-web/purchasedhistory/list`,
    'addonRegister': `${environment.apiUrl}/customer-web/register`,
    'addonv2': `${environment.apiUrl}/customer-web//purchase/addon/v2`,
    'phaselisting': `${environment.apiUrl}/phase/list`,
    'phasecategory': `${environment.apiUrl}/phasecategory/list`,
    'pioneerfocus': `${environment.apiUrl}/pioneer-focus/list`,
    'pioneercategory': `${environment.apiUrl}/pioneer-category/list`,
    'pioneeraddresource': `${environment.apiUrl}/pioneer-resource/add`,
    'pioneerlist': `${environment.apiUrl}/pioneer-resource/list?`,
    'pioneersearch': `${environment.apiUrl}/pioneer-resource/search?`,
    'addfavourite': `${environment.apiUrl}/customer-favourite/add`,
    'removefavourite': `${environment.apiUrl}/customer-favourite/remove`,
    'post': `${environment.apiUrl}/posts`,
    'states': `${environment.apiUrl}/state/list`,
    'denomination': `${environment.apiUrl}/denomination/list`,
    'focusarea': `${environment.apiUrl}/focusarea/list`,
    'fivephasecat': `${environment.apiUrl}/fivephasecat/list`,
    'fxAdd': `${environment.apiUrl}/customer-fx-management/add`,
    'fxEdit': `${environment.apiUrl}/customer-fx-management/update`,
    'leader': `${environment.apiUrl}/logedin/leader`,
    'customersList': `${environment.apiUrl}/leaders/list`,
    'fxCustomerDetail': `${environment.apiUrl}/customer-fx-management/details`,
    'favoriteslisting': `${environment.apiUrl}/customer-favourite/list`,
    'customeralldependencies': `${environment.apiUrl}/customer/alldependencies`,
    'FXList': `${environment.apiUrl}/customer-fx-management/list`,
    'uploadList': `${environment.apiUrl}/customer-upload/list`,
    'deleteFX': `${environment.apiUrl}/customer-fx-management/delete`,
    'deleteResource': `${environment.apiUrl}/customer-upload/delete`,
    'addFavUpload': `${environment.apiUrl}/customer-favourite/add`,
    'removeFavUpload': `${environment.apiUrl}/customer-favourite/remove`,
    'notificationCount': `${environment.apiUrl}/customer/notification/count`,
    'googleDrive': `${environment.apiUrl}/customer/googledrivelisting`,
    'createPost': `${environment.apiUrl}/post/create`,
    'postAnnounce': `${environment.apiUrl}/post/announcement`,
    'editPost': `${environment.apiUrl}/post/edit`,
    'deletePost': `${environment.apiUrl}/post/delete`,
    'reportPost': `${environment.apiUrl}/post/report/abuse`,
    'singlePost': `${environment.apiUrl}/post/get`,
    'createComment': `${environment.apiUrl}/post/comment/create`,
    'likeUnlike': `${environment.apiUrl}/post/like-unlike`,
    'postComments': `${environment.apiUrl}/post/comments`,
    'commentLikeUnlike': `${environment.apiUrl}/post/comment/like-unlike`,
    'commentUpdate': `${environment.apiUrl}/post/comment/update `,
    'postLikes': `${environment.apiUrl}/post/likes`,
    'commentDelete': `${environment.apiUrl}/post/comment/delete`,
    'userAgreement': `${environment.apiUrl}/customer/agreement`,
    'uploaddetails': `${environment.apiUrl}/customer-upload/details`,
    'customeruploadupdate': `${environment.apiUrl}/customer-upload/update`,
    'customerGroupList': `${environment.apiUrl}/customer-group/list`,
    'customerSubscription': `${environment.apiUrl}/custom-subscription/custom-list`,
    'notification': `${environment.apiUrl}/customer/notifications`,
    'readNotification': `${environment.apiUrl}/customer/notification/read`,
    'updateGroupStatus': `${environment.apiUrl}/customer-group/request`,
    'notificationType': `${environment.apiUrl}/notifications-type/list`,
    'settingSave': `${environment.apiUrl}/customer/notification-setting/save`,
    'settingList': `${environment.apiUrl}/customer/notification/setting`,
    'customGroupDropdown': `${environment.apiUrl}/customer-group/dropDown`,


    // http://localhost:3001/customer-web/purchasedaddons/list (GET)
    // http://localhost:3001/customer-web/purchasedaddons/details?addons_id=1 (GET)


    'fxmaplisting': `${environment.apiUrl}/fx-map/list`,
    'fxMapDetail': `${environment.apiUrl}/fx-map/details`,
    'mypostList': `${environment.apiUrl}/user/posts`,

    'emailChange': `${environment.apiUrl}/customer-web/emailchange`,
    'emailverification': `${environment.apiUrl}/customer-web/emailverify`,
    
    'addCustomSubscription': `${environment.apiUrl}/customer-web/custom/addsubscription`,
    'removeCustomSubscription': `${environment.apiUrl}/customer-web/custom/removesubscription`,
    'addonCategory': `${environment.apiUrl}/customer-web/addon-category/list`,
    'publicaddonCategory': `${environment.apiUrl}/public/addon-category/list`,
    'CreateCustomerSubscription': `${environment.apiUrl}/custom-subscription/plan`,
        'cmtpostLikes': `${environment.apiUrl}/post/comment-likes`,
    'fxRemoveImg': `${environment.apiUrl}/fx-management/imagedelete`,
    'deleteAccount': `${environment.apiUrl}/customer/delete`,
    'bulkNotification': `${environment.apiUrl}/admin/notifications`,

});

export const SECRET_KEY: string = '$2a$10$e.oPc.dyrwRoQCpDvO9Rhe';
