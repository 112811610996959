// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular.json`.

export const environment = {
    production: true,
    apiUrl: 'https://api.fxconnectus.org', // For Production
    assetsUrl: 'https://fx-static-prod.s3.amazonaws.com/',
    cometChat: {
        apiKey: '65e312da12f7368b7285c8e4c7ac1c80b4f42c74',
        appId: '2297785d410e6e9e',
        appRegion: 'us'
    }
};
