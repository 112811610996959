import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';


const routes: Routes = [
    { path: '', loadChildren: () => import('./pages/homepage-new/homepage.module').then(m => m.HomepageModule) },
    { path: 'home', loadChildren: () => import('./pages/homepage-new/homepage.module').then(m => m.HomepageModule) },
    { path: 'add-on', loadChildren: () => import('./pages/add-on-listing/add-on-listing.module').then(m => m.AddOnListingModule) },
    { path: 'add-on-new', loadChildren: () => import('./pages/add-on-listing-new/add-on-listing-new.module').then(m => m.AddOnListingNewModule) },
    { path: 'subscription-packages', loadChildren: () => import('./pages/subscrption-packages/subscrption-packages.module').then(m => m.SubcriptionPackagesModule) },
    { path: 'login', loadChildren: () => import('./pages/login/login.module').then(m => m.LoginModule) },
    { path: 'create-account', loadChildren: () => import('./pages/create-account/create-account.module').then(m => m.CreateAccountModule) },
    { path: 'support', loadChildren: () => import('./pages/support/support.module').then(m => m.SupportModule) },
    { path: 'terms-conditions', loadChildren: () => import('./pages/terms-conditions/terms-conditions.module').then(m => m.TermsAndConditionModule) },
    { path: 'welcome', loadChildren: () => import('./pages/welcome/welcome.module').then(m => m.WelcomeModule) },
    // { path: 'edit-profile', loadChildren: () => import('./pages/edit-profile/edit-profile.module').then(m => m.EditProfileModule) },
    // { path: '', loadChildren: () => import('./pages/sidebar-layout/sidebar-layout.module').then(m => m.SidebarLayoutModule) },
    // { path: '**', redirectTo: 'welcome', pathMatch: 'full'}
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {
        scrollPositionRestoration: 'top'

    })],
    exports: [RouterModule]
})
export class AppRoutingModule { }
